@media screen and (min-width: 700px) {
    h2 {
        font-size: calc(1em + 1.5vw);
    }
        
    #services {
        display: flex;
        flex-direction: column;
    }

    .service {
        display: flex;
        justify-content: space-between;
        margin-top: 10vh;
    }

    .service .content-figure {
        flex: 1;
        margin: 0;
    }

    .service .content {
        flex: 1.25;
        margin: 0 5vw;
    }

    #process .section-break {
        margin-top: 10vh;
    }
}
